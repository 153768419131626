import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPresenters(ctx, queryParams) {
      console.log(queryParams)
      const { limit } = queryParams
      const { page } = queryParams
      const { q } = queryParams

      const uri = `/radio/admin/programmers?page=${page}&limit=${limit}&q=${q}`

      return new Promise((resolve, reject) => {
        axios
          .get(uri)
          .then(response => resolve(response.data ? response.data.data : {}))
          .catch(error => reject(error))
      })
    },
    fetchPresenter(ctx, id) {
      const uri = `/radio/admin/programmers/${id}`

      return new Promise((resolve, reject) => {
        axios
          .get(uri)
          .then(response => resolve(response.data ? response.data.data : {}))
          .catch(error => reject(error))
      })
    },
    updatePresenter(ctx, payload) {
      const uri = '/radio/admin/programmers'

      return new Promise((resolve, reject) => {
        axios
          .put(uri, payload)
          .then(response => resolve(response.data ? response.data.data : {}))
          .catch(error => reject(error))
      })
    },
    createPresenter(ctx, payload) {
      const uri = '/radio/admin/programmers'

      return new Promise((resolve, reject) => {
        axios
          .post(uri, payload)
          .then(response => resolve(response.data ? response.data.data : {}))
          .catch(error => reject(error))
      })
    },
    deletePresenter(ctx, id) {
      const uri = `/radio/admin/programmers?id=${id}`

      return new Promise((resolve, reject) => {
        axios
          .delete(uri)
          .then(response => resolve(response.data ? response.data.data : {}))
          .catch(error => reject(error))
      })
    },
  },
}
